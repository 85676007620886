export const clientNameFooter = "ANA LUGH YOGA";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "ANA LUGH YOGA";
export const nomeFornecedor = "ANA LUGH YOGA";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/analughyoga/",
    profile: "@analughyoga"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com",
  },

  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send/?phone=5511992489301&text&type=phone_number&app_absent=0",
    number: "(11) 99248-9301"
  },
];

export const drawerClosedLinks = [
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [
  {
    title: "Playlist",
    link: "https://open.spotify.com/show/7xAfvquK96l0goTmwl8wOK?si=zPLyJHRpQNaetQehIZZ6hA&nd=1",
  },
  {
    title: "Comunidade Prana&Ana",
    link: "https://api.whatsapp.com/send/?phone=5511992489301&text&type=phone_number&app_absent=0",
  },];
